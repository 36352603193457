<template>
  <div>
    <div id="main" class="site-main">

<div id="main-content" class="main-content">

<div id="page-title" class="page-title-block page-title-style-1 " style=""><div class="container"><div class="page-title-title"><h1 style="">  Школа лекторов</h1></div></div></div>
	<div class="block-content">
		<div class="container">
			<div class="panel row panel-sidebar-position-right with-sidebar">


 <newsblockUniversal :category="shkolalektorov_articles" count=7 :page="showpage" />



				<div class="sidebar col-lg-3 col-md-3 col-sm-12" role="complementary"><div class="widget-area">

<searchbar />
<calendar />


  <rubricator position="lectors_school"/>
<tagscloud/>
</div>
</div><!-- .sidebar -->			</div>
		</div><!-- .container -->
	</div><!-- .block-content -->
</div><!-- #main-content -->

<!-- Your Google Analytics Plugin is missing the tracking ID -->

		</div><!-- #main -->
  </div>
</template>

<script>
import {rollup} from "@/lib/utils.js"
import categories from "@/lib/categories.js"
import rubricator from "@/components/rubricator.vue"
import tagscloud from "@/components/tagscloud.vue"
import newsblockUniversal from "@/components/news/newsblock-universal.vue"
import searchbar from "@/components/searchbar.vue"
import calendar from "@/components/calendar.vue"
export default {

name : 'lectors_school_articles',
components : {rubricator,tagscloud,newsblockUniversal,searchbar, calendar},
mounted() {
rollup()



},
data() {
let showpage = 1
  //console.log(this.$route.params)

  if(this.$route.params.page)
  {
    showpage = this.$route.params.page
  }

return {
    shkolalektorov_articles : categories.shkolalektorov_articles,
    showpage : showpage
     }

}

}

</script>
